import React, { useState, useEffect } from 'react'
import { Router } from '@reach/router'
import sanityClient from '@sanity/client'
import Alert from '@app/alert'
import Page from '../templates/page'
import ProductPage from '../templates/product-page'
import ProductCategory from '../templates/category-page'
import Loading from '@app/loading'
import TemplatePreview from '../components/template-preview'
import pluginPreviews from '@rdk/config/sanity-previews'

const client = sanityClient({
	projectId: process.env.SANITY_PROJECT_ID,
	dataset: process.env.SANITY_DATASET,
	useCdn: false,
	withCredentials: true,
})

function PreviewPage(props){
	const [data, setData] = useState()

	useEffect(() => {
		if(typeof window === `undefined`) return
		async function fetchData(){
			let data = await deepFetch({ id: props.document })
			data = Array.isArray(data) ? data[0] : (data || {})

			// Also fetch products for category if necessary
			if (data._type === `productCategory`) {
				let products = await fetchCategoryProducts(props.document)
				if(!Array.isArray(products)) products = [products]
				data = {
					_type: data._type,
					products,
					category: data,
				}
			}

			setData(data)
		}
		fetchData()
	}, [props.document])

	if(!data){
		return (
			<div>
				<Loading />
			</div>
		)
	}

	switch(data._type){
		case`page`:
			return <Page pageContext={{ page: data }} />
		case `product`:
			return <ProductPage pageContext={{ product: data }} mock={true} />
		case `productCategory`:
			return <ProductCategory pageContext={data} mock={true} />
		case `cartTemplate`:
		case `pageNotFoundTemplate`:
		case `pageTemplate`:
		case `searchTemplate`:
		case `siteTemplate`:
			return <TemplatePreview data={data} />
	}


	for (let i in pluginPreviews){
		if(data._type === i){
			const Component = pluginPreviews[i].default
			return (
				<Component data={data} client={client} />
			)
		}
	}

	return (
		<Alert type='error'>Page previews not supported for this content type.</Alert>
	)
}


const maxDepth = 10
async function deepFetch({ id, query }) {
	let data = await client.fetch(query || `*[_id == "${id}"]`)
	for (let i = maxDepth; i--;) {
		const referenceIds = findReferenceIds(data)
		const references = await fetchReferences(referenceIds)
		fillReferences(data, references)
	}
	return data
}

async function fetchCategoryProducts(id){
	const products = await deepFetch({ query: `*[_type == "product" && categories[]._ref == "${id}"]` })
	return products
}

function findReferenceIds(obj, referenceIds = []){
	if(typeof obj !== `object`) return referenceIds
	for(let i in obj){
		if(obj[i]._type && obj[i]._type === `reference`){
			referenceIds.push(obj[i]._ref)
		}
		findReferenceIds(obj[i], referenceIds)
	}
	return referenceIds
}
async function fetchReferences(arr){
	const entriesById = {}
	if (arr.length) {
		const query = `*[_id == "${arr.join(`" || _id == "`)}"]`
		const references = await client.fetch(query)
		references.forEach(ref => {
			entriesById[ref._id] = ref
		})
	}
	return entriesById
}
function fillReferences(obj, entriesByID) {
	if (typeof obj != `object`) return obj
	for (let i in obj) {
		if (obj[i]._ref) {
			if (!entriesByID[obj[i]._ref]) {
				console.error(`Reference not found!`)
				continue
			}
			obj[i] = entriesByID[obj[i]._ref]
		}
		obj[i] = fillReferences(obj[i], entriesByID)
	}
	return obj
}


export default function Preview() {
	return (
		<div>
			<Router>
				<PreviewPage path='/preview/:document' />
			</Router>
		</div>
	)
}