import React from 'react'
import get from 'lodash/get'
import Head from '@app/head'
import Modules from './sanity-module'
import Alert from '@app/alert'

export default function TemplatePreview({ data }) {

	const title = get(data, `title`, `Page Preview`)

	return(
		<>
			<Head title={title} />
			<Modules
				pageTitle={title}
				modules={[
					...get(data, `pageTemplate.headerModules`, []),
					...get(data, `headerModules`, []),
				]}
			/>
			<Alert type='info'>Page content will go here on the live website.</Alert>
			<Modules
				pageTitle={title}
				modules={[
					...get(data, `footerModules`, []),
					...get(data, `pageTemplate.footerModules`, []),
				]}
			/>
		</>
	)
}