import React, { useEffect, useState } from 'react'
import LayoutWrapper from '@app/layout-wrapper'
import { Global } from '@emotion/core'
import get from 'lodash/get'
import { renderStyles } from '@app/sanity-style-fields/css'

export default function StylesPreview({ client }) {
	const [code, setCode] = useState(`Loading preview HTML...`)
	const [styles, setStyles] = useState()

	useEffect(() => {
		async function query() {
			const styleDefs = await client.fetch(`*[_type == "styles"]{
				id,
				fontFamily,
				fontSize,
				color->,
				backgroundColor->,
				borderWidth,
				borderColor->,
				css,
				hoverState{
					color->,
					backgroundColor->,
					borderColor->,
					css,
				},
				activeState{
					color->,
					backgroundColor->,
					borderColor->,
					css,
				},
				focusState{
					color->,
					backgroundColor->,
					borderColor->,
					css,
				},
			}`)
			const cssBlocks = []
			styleDefs.map(def => {
				const styles = renderStyles([def])
				// if(def.id === `p`){
				// 	console.log(`p`, def)
				// }
				cssBlocks.push(`.${def.id}{${styles}}`)
			})
			setStyles(cssBlocks.join(``))

			const settings = await client.fetch(`*[_type == "stylesDevSettings"][0]`)
			const code = get(settings, `previewHtml.code`, `No preview HTML found. Set in Style Definitions developer settings.`)
			setCode(code)
		}
		query()
	}, [])


	return (
		<LayoutWrapper>
			<Global styles={styles} />
			<div dangerouslySetInnerHTML={{__html: code}} />
		</LayoutWrapper>
	)
}